<template>
    <div>

        <v-select :prepend-icon="icon ? icons.mdiAlphaCCircle : null" :clearable="clearable" hide-details="auto"
            :disabled="disabled" v-model="select" :items="lista" label="Tipo Documento" item-value="id"
            item-text="nombre" :dense="outlined" :outlined="outlined" :loading="cargando" :rules="rules">
            <template slot="selection" slot-scope="data">
                <v-list-item-avatar color="primary" class="  mr-2" size="20">
                    <small class="white--text">{{ data.item.abreviatura }}</small>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title v-text="data.item.nombre"></v-list-item-title>

                </v-list-item-content>
            </template>
            <template slot="item" slot-scope="data">
                <v-list-item-avatar color="primary" class="  mr-2" size="20">
                    <small class="white--text">{{ data.item.abreviatura }}</small>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title v-text="data.item.nombre"></v-list-item-title>

                </v-list-item-content>
                <v-list-item-action>
                    <v-list-item-subtitle v-text="data.item.tipoEntidad.nombre"></v-list-item-subtitle>
                </v-list-item-action>
            </template>
        </v-select>
    </div>

</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiAlphaCCircle, mdiPlusCircleOutline } from '@mdi/js'

import TipoDocumentoServices from '@/api/servicios/TipoDocumentoServices'
export default {

    props: {
        jsonRespueta: Boolean,
        disabled: Boolean,
        dense: Boolean,
        outlined: Boolean,
        icon: Boolean,
        todos: Boolean,
        default: Number,
        clearable: Boolean,
        padre: Number,
        rules: Array
    },
    setup(props, contex) {
        const lista = ref([])
        const select = ref(null)
        const cargando = ref(false)
        const init = ref(false)
        onBeforeMount(() => {
            cargarDatos()
            if (props.default != undefined) {
                select.value = props.default
                init.value = true
            }
        })

        const selectId = (id) => { 
             
            if (select.value != id) {
                select.value = id
            }else{
                ResultDatos()
            }
            
        }
        const cargarDatos = () => {
            lista.value = []
            cargando.value = true
            try {

                TipoDocumentoServices.ConsultarSelect({
                    Id: -1,
                    IdTipoEntidad: -1,
                    Nombre: '',
                    IndActivo: true
                }).then(response => {
                    console.log(response)
                    if (response.data.estatus == true) {
                        lista.value = response.data.datos
                        if (init.value == true) {
                            init.value = false
                            //retornar datos default
                            ResultDatos()
                        }
                    }
                })
                    .catch(error => {
                        console.log(error)
                    })
                    .finally(() => {
                        cargando.value = false
                    })
            } catch (error) {

                cargando.value = false
            }
        }


        const ResultDatos = () => {
            if (props.jsonRespueta == true) {
                const valor = lista.value.find(item => (item.id == select.value))
                contex.emit('input',
                    valor == undefined ? null : valor
                )
            } else {
                contex.emit('input',
                    select.value
                )
            }
        }
        watch(select, () => {
            ResultDatos()
        })

        return {
            icons: {
                mdiAlphaCCircle,
                mdiPlusCircleOutline
            },
            lista,
            select,
            cargando,
            selectId
        }
    },
}
</script>