<template>
    <v-card flat class="pa-3 mt-2">
        <v-card-subtitle>
            Proveedor
        </v-card-subtitle>
        <v-form ref="form" lazy-validation>
            <v-card-text class="d-flex">
                <v-row>
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12" lg="6">

                                <SelectPais :rules="reglas.idPais" class="mt-2" :default="117" :clearable="true"
                                    :dense="true" :outlined="true" :icon="false" v-model="datos.idPais"></SelectPais>
                                <v-row class="mt-2">
                                    <v-col cols="4">
                                        <SelectTipoDocumento :jsonRespueta="true" ref="SelectTipoDocumentoRef"
                                            :rules="reglas.IdtipoDocumento" :default="1" :dense="true" :outlined="true"
                                            :icon="false" v-model="datos.TipoDocumento"></SelectTipoDocumento>

                                    </v-col>
                                    <v-col cols="8">
                                        <v-text-field :prefix="datos.TipoDocumento.abreviatura+'-'" :rules="reglas.documento" v-model="datos.documento"
                                            label="Documento *" required outlined dense
                                            hide-details="auto"></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-text-field class="mt-2" :rules="reglas.razonSocial" v-model="datos.razonSocial"
                                    label="Razon Social *" required outlined dense hide-details="auto"></v-text-field>

                                <v-text-field class="mt-2" :rules="reglas.razonComercial" v-model="datos.razonComercial"
                                    label="Razon Comercial *" required outlined dense
                                    hide-details="auto"></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="6">
                                <v-text-field class="mt-2" v-model="datos.telefono" label="Telefono" required outlined
                                    dense hide-details="auto"></v-text-field>

                                <v-text-field class="mt-2" v-model="datos.correo" label="Correo" required outlined dense
                                    hide-details="auto"></v-text-field>

                                <v-text-field class="mt-2" v-model="datos.paginaWeb" label="Pagina Web" required
                                    outlined dense hide-details="auto"></v-text-field>


                                <v-text-field class="mt-2" v-model="datos.imagen" label="Imagen" required outlined dense
                                    hide-details="auto"></v-text-field>

                                <v-switch v-model="datos.indActivo" :label="`Ind Activo`"></v-switch>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="text-right">
                       
                        <v-btn color="secondary" class="me-3  " @click="limpiar()" small> Limpiar
                        </v-btn>
                        <v-btn color="primary" class="me-3  " small @click="Guardar()"> Guardar </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-form>
    </v-card>
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import store from '@/store'
import goTo from 'vuetify/lib/services/goto'
import SelectTipoDocumento from '../../../../maestro/tipo-documento/componentes/Select.vue'
import SelectPais from '../../../maestro/pais/componentes/Select.vue'

import ProveedorServices from '@/api/servicios/ProveedorServices'
export default {
    components: {
        SelectTipoDocumento,
        SelectPais
    },
    setup(props, context) {

        const guardando = ref(false)
        const form = ref(null)
        const SelectTipoDocumentoRef = ref(null)
        const datos = ref({
            "id": -1,
            "TipoDocumento":{},
            "idTipoDocumento": 1,
            "idPais": 117,
            "documento": "",
            "razonSocial": "",
            "razonComercial": "",
            "telefono": "",
            "imagen": "",
            "correo": "",
            "paginaWeb": "",
            "indActivo": true
        })


        const reglas = {

            idTipoDocumento: [

                v => !!v || "TipoDocumento es requerido"
            ],
            idPais: [

                v => !!v || "Pais es requerido"
            ],
            documento: [ 
                v => !!v || "Documento requerido",
                v => (datos.value.TipoDocumento.idTipoEntidad == 1 ? /^(\d{6,9})$/.test(v) || "La cedula debe ser valida: ej V-12365450" : true),
                v => (datos.value.TipoDocumento.idTipoEntidad == 2 ? /^(\d{5,9}[\-]\d{1})$/.test(v) || "El Rif debe ser valido: ej J-12345678-0" : true),
            ],
            razonSocial: [

                v => !!v || "razonSocial es requerido"
            ],
            razonComercial: [

                v => !!v || "razonComercial es requerido"
            ]
        }
        
        const limpiar = () => {
            datos.value = {
                "id": -1,
                "TipoDocumento":{},
                "idTipoDocumento": 1,
                "idPais": 117,
                "documento": "",
                "razonSocial": "",
                "razonComercial": "",
                "telefono": "",
                "imagen": "",
                "correo": "",
                "paginaWeb": "",
                "indActivo": true
            };
            form.value.resetValidation();
            SelectTipoDocumentoRef.value.selectId(1)
        }

        const cargandos = ref(false)
        const idTipoDocumento = (item) => {
            datos.value.idTipoDocumento = item.id
        }

        const CargarEditar = (item) => {
            datos.value = item
            SelectTipoDocumentoRef.value.selectId(item.idTipoDocumento)
            goTo(0)
        }
        const validarForm = () => {
            let val = form.value?.validate()

            return val
        }
        const ActualizarLista = item => {
            console.log(item)
            context.emit('GetActualizarLista', { ...item })

        }
        const Guardar = () => {
            guardando.value = true
            if (validarForm()) {
                try {

                    ProveedorServices.Actualizar(
                        store.state.user.idUsuario,
                        datos.value.id,
                        datos.value.TipoDocumento.id,
                        datos.value.idPais,
                        datos.value.documento,
                        datos.value.razonSocial,
                        datos.value.razonComercial,
                        datos.value.telefono,
                        datos.value.imagen,
                        datos.value.correo,
                        datos.value.paginaWeb,
                        datos.value.indActivo,
                    ).then(response => {
                        console.log(response)
                        if (response.data.estatus == true) {
                            store.commit('setAlert', {
                                message: response.data.mensaje,
                                type: 'success',
                            })
                            ActualizarLista(response.data.datos.datos)
                            limpiar()
                        } else {

                            store.commit('setAlert', {
                                message: `${response.data.mensaje}: ` + response.data.datos.mensajeError,
                                type: 'warning',
                            })
                        }
                    })
                        .catch(error => {
                            console.log(error)
                            store.commit('setAlert', {
                                message: error,
                                type: 'error',
                            })
                        })
                        .finally(() => {
                            guardando.value = false
                        })
                } catch (error) {
                    //alert(error)
                    store.commit('setAlert', {
                        message: error,
                        type: 'error',
                    })
                }
            } else {
                store.commit('setAlert', {
                    message: 'Verifique que todos los datos estén completos',
                    type: 'warning',
                })
            }

        }


        return {
            reglas,
            datos,
            CargarEditar,
            cargandos,
            limpiar,
            Guardar,
            form,
            ActualizarLista,
            idTipoDocumento,
            SelectTipoDocumentoRef
        }
    }
}

</script>