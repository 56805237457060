//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'

class ProveedorServices {
    Consultar(
        datos = {
            "id": -1,
            "idTipoDocumento": -1,
            "idPais": -1,
            "documento": "",
            "razonSocial": "",
            "razonComercial": "",
            "telefono": "",
            "imagen": "",
            "correo": "",
            "paginaWeb": "",
            "indActivo": null
        }
    ) {
        return http.get("proveedor/consultar", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }

    ConsultarSelect(
        datos = {
            "id": -1,
            "idTipoDocumento": -1,
            "idPais": -1,
            "documento": "",
            "razonSocial": "",
            "razonComercial": "",
            "telefono": "",
            "imagen": "",
            "correo": "",
            "paginaWeb": "",
            "indActivo": null
        }
    ) {
        return http.get("proveedor/consultar/select", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }

    ConsultarBusqueda(
        datos = {
            "Busqueda": "" 
        }
    ) {
        return http.get("proveedor/consultar/busqueda", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }
    Actualizar(
        idUsuario,
        id,
        idTipoDocumento,
        idPais,
        documento,
        razonSocial,
        razonComercial,
        telefono,
        imagen,
        correo,
        paginaWeb,
        indActivo
    ) {
        const datos = {
            idUsuario,
            Data: {
                id,
                idTipoDocumento,
                idPais,
                documento,
                razonSocial,
                razonComercial,
                telefono,
                imagen,
                correo,
                paginaWeb,
                indActivo
            }
        }
        console.log(datos)
        return http.post("/proveedor/actualizar", datos)
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }
}


export default new ProveedorServices();