<template>
    <div>

        <v-autocomplete :prepend-icon="icon ? icons.mdiAlphaCCircle : null" :clearable="clearable" hide-details="auto"
            :disabled="disabled" v-model="select" :items="lista" label="Pais" item-value="id" item-text="nombre"
            :dense="outlined" :outlined="outlined" :loading="cargando" :rules="rules" >
            <template slot="selection" slot-scope="data">
                <v-avatar color="primary" size="20" class="mr-2"> 
                    <img :src="`https://flagcdn.com/16x12/${data.item.abreviatura1.toLowerCase()}.png`" :alt="data.item.abreviatura1">
                </v-avatar>
                {{ data.item.nombre }} ({{ data.item.abreviatura1 }})
             
            </template>
            <template slot="item" slot-scope="data">
                <v-avatar color="primary" size="20" class="mr-2"> 
                    <img :src="`https://flagcdn.com/16x12/${data.item.abreviatura1.toLowerCase()}.png`" :alt="data.item.abreviatura1">
                </v-avatar>
                 {{ data.item.nombre }} ({{ data.item.abreviatura1 }})
                
            </template>
        </v-autocomplete> 
    </div>

</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiAlphaCCircle, mdiPlusCircleOutline } from '@mdi/js'

import PaisServices from '@/api/servicios/PaisServices'
export default {

    props: {
        value:Number,
        jsonRespueta: Boolean,
        disabled: Boolean,
        dense: Boolean,
        outlined: Boolean,
        icon: Boolean,
        todos: Boolean,
        default: Number,
        clearable: Boolean,
        padre: Number,
        rules:Array
    },
    setup(props, contex) {
        const lista = ref([])
        const select = ref(null)
        const cargando = ref(false)

        
        watch(props, () => {
            select.value = props.value
         
        })
        onBeforeMount(() => {
            cargarDatos()
            if (props.default != undefined) {
                select.value = props.default
            }
        })

        const selectId = (id) => {

            select.value = id
        }
        const cargarDatos = () => {
            lista.value = []
            cargando.value = true
            try {

                PaisServices.ConsultarSelect({
                    id: -1,
                    idContinente: -1,
                    nombre: '',
                    capital: '',
                    codigoArea: '',
                    abreviatura1: '',
                    abreviatura2: '',
                    indActivo: null
                }).then(response => {
                    console.log(response)
                    if (response.data.estatus == true) {
                        lista.value = response.data.datos
                    }
                })
                    .catch(error => {
                        console.log(error)
                    })
                    .finally(() => {
                        cargando.value = false
                    })
            } catch (error) {

                cargando.value = false
            }
        }
        watch(select, () => {
            if (props.jsonRespueta == true) {
                const valor = lista.value.find(item => (item.id == select.value))
                contex.emit('input',
                    valor == undefined ? null : valor
                )
            } else {
                contex.emit('input',
                    select.value
                )
            }



        })

        return {
            icons: {
                mdiAlphaCCircle,
                mdiPlusCircleOutline
            },
            lista,
            select,
            cargando,
            selectId
        }
    },
}
</script>