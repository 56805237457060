//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'

class PaisServices {
     Consultar(
        datos = {
            id : -1,
            idContinente : -1,
            nombre : '',
            capital : '',
            codigoArea : '',
            abreviatura1 : '', 
            abreviatura2 : '',
            indActivo :null 
    
        }    
     ) {
        return http.get("pais/consultar", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    } 

     ConsultarSelect(
        datos = {
            id : -1,
            idContinente : -1,
            nombre : '',
            capital : '',
            codigoArea : '',
            abreviatura1 : '', 
            abreviatura2 : '',
            indActivo :null 
        }
    ) {
        return http.get("pais/consultar/select", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    } 
}


export default new PaisServices();